import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../store/actions/userActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Alert } from '@mui/material'
import Loader from '../common/Loader'
import './loginandsignup.css'

const SignUp = () => {
  const [bandname, setBandName] = useState('')
  const [bandusername, setBandUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [msg, setMsg] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister
  const back = () => {
    navigate('/login')
  }
  const welcome = () => {
    navigate('/')
  }
  const submitHandler = (e) => {
    e.preventDefault()
    //DISPATCH Register
    if (bandname && bandusername && password) {
      dispatch(register(bandname, bandusername, password, email, phone))
      navigate('/login')
    } else {
      setMsg(true)
    }
  }
  return (
    <>
      {loading && <Loader />}
      <ArrowBackIcon style={{ color: 'white' }} onClick={back} />{' '}
      <div style={{ textAlign: 'right' }}>
        <Button style={{ color: 'white' }} onClick={welcome}>
          BandApp
        </Button>
      </div>
      <div class='ring'>
        <i style={{ '--clr': '#00ff0a' }}></i>
        <i style={{ '--clr': '#ff0057' }}></i>
        <i style={{ '--clr': '#fffd44' }}></i>
        <div class='login'>
          <h3>Sign Up</h3>
          <div class='inputBx'>
            <input
              type='text'
              placeholder='Band Name'
              onChange={(e) => setBandName(e.target.value)}
            />
          </div>
          <div class='inputBx'>
            <input
              type='text'
              required
              placeholder='Username'
              onChange={(e) => setBandUserName(e.target.value)}
            />
          </div>
          <div class='inputBx'>
            <input
              type='password'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <center>
            <p style={{ color: 'white' }}>
              {'One Login for your entire Band & Events!'}
            </p>
          </center>
          <div class='inputBx btn'>
            <input type='submit' value='Submit' onClick={submitHandler} />
          </div>
        </div>
        <br />
        <div style={{ margin: '800px 0px 0px 0px' }}>
          {msg && (
            <center>
              <Alert severity='error'>
                Please check and provide the details correctly.
              </Alert>
            </center>
          )}
        </div>
      </div>
    </>
  )
}

export default SignUp
