import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const SnackBarComponent = ({ isOpen, msg }) => {
  const [open, setOpen] = React.useState(isOpen)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  const action = (
    <React.Fragment>
      <Button color='secondary' size='small' onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </React.Fragment>
  )

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </div>
  )
}

export default SnackBarComponent
