// USER Starts
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_CHEF_DETAILS_GUEST_REQUEST = 'USER_CHEF_DETAILS_GUEST_REQUEST'
export const USER_CHEF_DETAILS_GUEST_SUCCESS = 'USER_CHEF_DETAILS_GUEST_SUCCESS'
export const USER_CHEF_DETAILS_GUEST_FAIL = 'USER_CHEF_DETAILS_GUEST_FAIL'
export const USER_CHEF_DETAILS_GUEST_RESET = 'USER_CHEF_DETAILS_GUEST_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'
// USER Ends

// EVENT Starts

export const EVENT_REGISTER_REQUEST = 'EVENT_REGISTER_REQUEST'
export const EVENT_REGISTER_SUCCESS = 'EVENT_REGISTER_SUCCESS'
export const EVENT_REGISTER_FAIL = 'EVENT_REGISTER_FAIL'

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL'

export const EVENT_UPDATE_MAIN_REQUEST = 'EVENT_UPDATE_MAIN_REQUEST'
export const EVENT_UPDATE_MAIN_SUCCESS = 'EVENT_UPDATE_MAIN_SUCCESS'
export const EVENT_UPDATE_MAIN_FAIL = 'EVENT_UPDATE_MAIN_FAIL'

export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST'
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS'
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL'
export const EVENT_LIST_RESET = 'EVENT_LIST_RESET'

export const EVENT_ADD_SONG_UPDATE_REQUEST = 'EVENT_ADD_SONG_UPDATE_REQUEST'
export const EVENT_ADD_SONG_UPDATE_SUCCESS = 'EVENT_ADD_SONG_UPDATE_SUCCESS'
export const EVENT_ADD_SONG_UPDATE_FAIL = 'EVENT_ADD_SONG_UPDATE_FAIL'

export const EVENT_GET_SONG_REQUEST = 'EVENT_GET_SONG_REQUEST'
export const EVENT_GET_SONG_SUCCESS = 'EVENT_GET_SONG_SUCCESS'
export const EVENT_GET_SONG_FAIL = 'EVENT_GET_SONG_FAIL'

export const EVENT_DELETE_SONG_REQUEST = 'EVENT_DELETE_SONG_REQUEST'
export const EVENT_DELETE_SONG_SUCCESS = 'EVENT_DELETE_SONG_SUCCESS'
export const EVENT_DELETE_SONG_FAIL = 'EVENT_DELETE_SONG_FAIL'

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL'

// EVENT Ends
