import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const CommonRadioGroup = ({ label, value, setFinalValue, value1, value2 }) => {
  const handleChange = (event) => {
    setFinalValue(event.target.value)
  }
  return (
    <FormControl>
      <FormLabel id='demo-row-radio-buttons-group-label'>{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value={value1} control={<Radio />} label={value1} />
        <FormControlLabel value={value2} control={<Radio />} label={value2} />
      </RadioGroup>
    </FormControl>
  )
}

export default CommonRadioGroup
