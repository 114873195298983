import React, { useState, useEffect } from 'react'
import { Button, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Loader from '../common/Loader'
import { login } from '../../store/actions/userActions'
import './loginandsignup.css'

const Login = () => {
  const [bandusername, setBanduserName] = useState('')
  const [password, setPassword] = useState('')
  const [msg, setMsg] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      navigate('/bandpage')
    }
  }, [navigate, userInfo])

  const back = () => {
    navigate('/')
  }
  const signup = () => {
    navigate('/signup')
  }
  const welcome = () => {
    navigate('/')
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (bandusername && password) {
      dispatch(login(bandusername, password))
      if (userInfo) {
        setMsg(false)
        navigate('/bandpage')
      } else {
        navigate('/login')
      }
    } else {
      setMsg(true)
    }
  }
  return (
    <>
      {loading && <Loader />}
      <ArrowBackIcon style={{ color: 'white' }} onClick={back} />{' '}
      <div style={{ textAlign: 'right' }}>
        <Button style={{ color: 'white' }} onClick={welcome}>
          BandApp
        </Button>
      </div>
      <div class='ring'>
        <i style={{ '--clr': '#00ff0a' }}></i>
        <i style={{ '--clr': '#ff0057' }}></i>
        <i style={{ '--clr': '#fffd44' }}></i>
        <div class='login'>
          <h3>Login</h3>
          <div class='inputBx'>
            <input
              type='text'
              placeholder='Username'
              onChange={(e) => setBanduserName(e.target.value)}
            />
          </div>
          <div class='inputBx'>
            <input
              type='password'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <center>
            <p style={{ color: 'white' }}>
              {'One Login for your entire Band & Events!'}
            </p>
          </center>
          <div class='inputBx btn'>
            <input type='submit' value='Login' onClick={handleSubmit} />
          </div>
          <div class='inputBx btn'>
            <input type='submit' value='Sign Up' onClick={signup} />
          </div>
        </div>
        {/* <div style={{ margin: '400px 0px 0px 0px', border: 'solid red 2px' }}>
          {msg && <Alert severity='error'>Please check your inputs.</Alert>}
        </div> */}
        <br />
        <div style={{ margin: '800px 0px 0px 0px' }}>
          {error && <Alert severity='error'>{error}</Alert>}
        </div>
      </div>
    </>
  )
}

export default Login
