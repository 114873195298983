import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  Button,
  AppBar,
  Container,
  Toolbar,
  Modal,
} from '@mui/material'
import AddEvent from '../events/AddEvent'
import AddBandMembers from './AddBandMembers'
import BandMemberList from './BandMemberList'
import EventsList from '../events/EventsList'
import { getBandUserDetails, logout } from '../../store/actions/userActions'
import Grid from '@mui/material/Grid'
import Loader from '../common/Loader'
import './bandusers.css'

const BandPage = () => {
  const [addEventBtn, setAddEventBtn] = React.useState(false)
  const [addMemberBtn, setAddMemberBtn] = React.useState(false)

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  const eventModal = () => {
    // Open a Modal with Event Name, Event Date and Event Location
    setAddEventBtn(true)
  }

  const back = () => {
    navigate('/')
  }

  const addMembers = () => {
    setAddMemberBtn(true)
  }

  const logoutBtn = () => {
    dispatch(logout())
    navigate('/')
  }

  React.useEffect(() => {
    // dispatch getBandUserDetails
    dispatch(getBandUserDetails(userInfo._id))
  }, [])

  return (
    <>
      <div className='band-page'>
        {loading && <Loader />}
        {loadingUserDetails && <Loader />}
        <AppBar color='transparent' position='static'>
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              <Grid item xs={6} style={{ margin: '20px 0px 20px 0px' }}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='h4'>
                      Welcome, "{userInfo.bandname.toUpperCase()}"
                    </Typography>
                    <Typography sx={{ mb: 0.5 }} color='text.secondary'>
                      " Music is the shorthand of emotion & Where words fail,
                      music speaks "
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} style={{ margin: '20px' }}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='h6'>
                      - "{userInfo.bandname.toUpperCase()}" - Band Members -
                    </Typography>

                    <Typography color='text.secondary'>
                      {user.members.map((member) => member.name + ' | ')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={2} style={{ margin: '10px' }}>
                <Container sx={{ flexGrow: 1 }}>
                  <Button
                    variant='contained'
                    color='success'
                    size='small'
                    onClick={handleOpen}
                  >
                    Band Members List
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    style={{ backgroundColor: 'black' }}
                  >
                    <BandMemberList setOpen={setOpen} />
                  </Modal>{' '}
                  <br />
                  <br />
                  <Button variant='contained' onClick={addMembers} size='small'>
                    Add Band Members
                  </Button>{' '}
                </Container>
              </Grid>
              <br />
              <br />
              <Button
                style={{
                  backgroundColor: '#F2C18D',
                  fontSize: '12px',
                  color: 'black',
                  padding: '5px',
                  margin: '10px',
                }}
                variant='outlined'
                onClick={logoutBtn}
              >
                Logout
              </Button>
            </Toolbar>
          </Container>
        </AppBar>
        {addEventBtn && (
          <AddEvent addEventBtn={addEventBtn} setAddEventBtn={setAddEventBtn} />
        )}
        <br />
        {addMemberBtn && (
          <AddBandMembers
            addMemberBtn={addMemberBtn}
            setAddMemberBtn={setAddMemberBtn}
          />
        )}

        <EventsList />
        <br />
        <div>
          <center>
            <Button variant='contained' onClick={eventModal} size='small'>
              Add Event
            </Button>
          </center>
          {/* <Switch color='warning' /> */}
        </div>
      </div>
    </>
  )
}

export default BandPage
