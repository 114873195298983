import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ScreenCapture } from 'react-screen-capture'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button, Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'
import Switch from '@mui/material/Switch'
import dayjs from 'dayjs'
import Alert from '@mui/material/Alert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LyricsIcon from '@mui/icons-material/Lyrics'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Modal from '@mui/material/Modal'
import EditSong from './EditSong'
import SongLyrics from './SongLyrics'
import {
  getSongFromEvent,
  getAllEventsByBandUserId,
  deleteSongFromEvent,
} from '../../store/actions/eventActions'
import AddSong from './AddSong'
import ShareEvents from './ShareEvents'
import './events.css'

const EventDetails = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = React.useState({ screenCapture: '' })
  const [openEditSongModal, setOpenEditSongModal] = useState(false)
  const [openAddSongModal, setOpenAddSongModal] = useState(false)
  const [openEditSongLyricsModal, setOpenEditSongLyricsModal] = useState(false)
  const [openShareEventModal, setOpenShareEventModal] = useState(false)
  const [showCaptureProgressBtn, setShowCaptureProgressBtn] = useState(false)
  const [entireLyrics, setEntireLyrics] = useState('')
  const [shareBtnClicked, setShareBtnClicked] = useState(false)
  const [viewLyrics] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const eventList = useSelector((state) => state.eventList)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    eventListBandUser,
  } = eventList

  const eventDetails = eventListBandUser.filter(
    (evnt) => evnt._id === params.id
  )

  const eventGetSong = useSelector((state) => state.eventGetSong)
  const {
    loading: loadingSongDetails,
    error: errorSongDetails,
    eventSong,
  } = eventGetSong

  useEffect(() => {
    // dispatch getBandUserDetails
    dispatch(getAllEventsByBandUserId(userInfo._id))
  }, [dispatch, userInfo._id, openAddSongModal, openEditSongModal])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = async () => {
    setOpenEditSongModal(false)
    await dispatch(getAllEventsByBandUserId(userInfo._id))
  }
  const handleSongLyricsClose = () => setOpenEditSongLyricsModal(false)
  const handleEdit = async (songid) => {
    await dispatch(getSongFromEvent(params.id, songid))
    setOpenEditSongModal(true)
  }
  const handleLyrics = (lyrics) => {
    setEntireLyrics(lyrics)
    setOpenEditSongLyricsModal(true)
  }

  const handleAddSong = () => {
    setOpenAddSongModal(true)
  }
  const back = () => {
    navigate('/bandpage')
  }

  const handleDelete = async (songid) => {
    if (window.confirm('Are you sure?')) {
      await dispatch(deleteSongFromEvent(params.id, songid))
      await dispatch(getAllEventsByBandUserId(userInfo._id))
    }
  }

  const handleShareEventClose = () => {
    setShareBtnClicked(false)
  }

  const handleShareEvents = () => {
    // navigate(`/shareevents`)
    setShareBtnClicked(true)
    setOpenShareEventModal(true)
  }

  const handleScreenCapture = (screenCapture) => {
    setState({ screenCapture })
  }

  const handleSave = () => {
    const screenCaptureSource = state.screenCapture
    const downloadLink = document.createElement('a')
    const fileName = `${eventDetails[0].events.name}.png`

    downloadLink.href = screenCaptureSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const handleSwitch = (event) => {
    setShowCaptureProgressBtn(event.target.checked)
  }

  return (
    <>
      {eventDetails && eventDetails.length > 0 ? (
        <>
          <ScreenCapture onEndCapture={handleScreenCapture}>
            {({ onStartCapture }) => (
              <center>
                <div className='event-details-page'>
                  <br />
                  <div style={{ color: 'white', margin: '20px' }}>
                    <ArrowBackIcon
                      style={{ margin: '10px 1500px 0px 0px' }}
                      onClick={back}
                    />
                    <h1 style={{ color: 'green' }}>
                      Event & Band Prep Details
                    </h1>
                    <h4>
                      <strong style={{ color: 'black' }}>
                        Name: {eventDetails[0].events.name} |
                      </strong>{' '}
                      <strong style={{ color: 'black' }}>
                        Location: {eventDetails[0].events.location} |
                      </strong>{' '}
                      <strong style={{ color: 'black' }}>
                        Date & Time:{' '}
                        {dayjs(eventDetails[0].events.datetime).format(
                          'MM/DD/YYYY HH:mm:ss'
                        )}
                      </strong>{' '}
                      <Button
                        style={{
                          backgroundColor: '#F2C18D',
                          fontSize: '12px',
                          color: 'black',
                          padding: '5px',
                          margin: '10px',
                        }}
                        onClick={handleShareEvents}
                      >
                        Share!
                      </Button>
                      {shareBtnClicked && (
                        <Modal
                          style={{
                            margin: '250px',
                            padding: '30px',
                            border: 'solid',
                            borderColor: 'white',
                            overflow: 'auto',
                            opacity: 1,
                            backgroundColor: 'black',
                          }}
                          open={openShareEventModal}
                          onClose={handleShareEventClose}
                          aria-labelledby='modal-modal-title'
                          aria-describedby='modal-modal-description'
                        >
                          {/* <ShareEvents viewLyrics={viewLyrics} data={entireLyrics} /> */}
                          <ShareEvents data={eventDetails[0].events} />
                        </Modal>
                      )}
                    </h4>
                  </div>
                  <div
                    style={{ margin: '20px 100px 20px 100px', opacity: 0.8 }}
                  >
                    <TableContainer component={Card}>
                      <Table sx={{ minWidth: 300 }} aria-label='simple table'>
                        {eventDetails[0].events.eventDetails &&
                          eventDetails[0].events.eventDetails.length > 0 && (
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <strong>Song Name</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Song Link</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Scale</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Tempo</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Improvisation</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Time</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Practise Needed</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Performers</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Lyrics</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Edit</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Delete</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                        <TableBody>
                          {eventDetails[0].events.eventDetails &&
                          eventDetails[0].events.eventDetails.length > 0 ? (
                            eventDetails[0].events.eventDetails
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => (
                                <TableRow
                                  key={row._id}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component='th' scope='row'>
                                    {row.songname}
                                  </TableCell>
                                  <TableCell>
                                    {row.songlink ? (
                                      <a
                                        href={row.songlink}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        {'Click Here!'}
                                      </a>
                                    ) : (
                                      'NA'
                                    )}
                                  </TableCell>
                                  <TableCell>{row.scale}</TableCell>
                                  <TableCell>{row.tempo}</TableCell>
                                  <TableCell>{row.improvisationDone}</TableCell>
                                  <TableCell>{row.timeTaken}</TableCell>
                                  <TableCell>{row.practiseNeeded}</TableCell>
                                  <TableCell>{row.performers}</TableCell>
                                  <TableCell>
                                    <LyricsIcon
                                      onClick={() => handleLyrics(row.lyrics)}
                                    />
                                  </TableCell>
                                  <Modal
                                    className='modal-view-lyrics'
                                    open={openEditSongLyricsModal}
                                    onClose={handleSongLyricsClose}
                                    aria-labelledby='modal-modal-title'
                                    aria-describedby='modal-modal-description'
                                  >
                                    <SongLyrics
                                      viewLyrics={viewLyrics}
                                      data={entireLyrics}
                                    />
                                  </Modal>{' '}
                                  <TableCell>
                                    <EditIcon
                                      onClick={() => handleEdit(row._id)}
                                    ></EditIcon>
                                  </TableCell>
                                  <Modal
                                    style={{ margin: '400px 600px 20px 600px' }}
                                    open={openEditSongModal}
                                    onClose={handleClose}
                                    aria-labelledby='modal-modal-title'
                                    aria-describedby='modal-modal-description'
                                  >
                                    <EditSong
                                      data={eventSong}
                                      openEditSongModal
                                      setOpenEditSongModal={
                                        setOpenEditSongModal
                                      }
                                    />
                                  </Modal>{' '}
                                  <TableCell>
                                    <DeleteIcon
                                      onClick={() => handleDelete(row._id)}
                                    ></DeleteIcon>
                                  </TableCell>
                                </TableRow>
                              ))
                          ) : (
                            <center>
                              <Alert style={{ margin: '20px' }}>
                                No Songs Added
                              </Alert>
                            </center>
                          )}
                        </TableBody>
                      </Table>
                      <br />

                      <center>
                        <TablePagination
                          component='div'
                          count={eventDetails[0].events.eventDetails.length}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          rowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </center>
                      <br />
                    </TableContainer>
                    <br />
                    <Button
                      variant='contained'
                      size='small'
                      onClick={handleAddSong}
                    >
                      Add Songs
                    </Button>
                    <Modal
                      style={{ margin: '400px 600px 20px 600px' }}
                      open={openAddSongModal}
                      onClose={handleClose}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <AddSong
                        openAddSongModal
                        setOpenAddSongModal={setOpenAddSongModal}
                      />
                    </Modal>
                    {/* <Button variant='contained' size='small'>
                Retro boards
              </Button> */}
                    <Switch
                      checked={showCaptureProgressBtn}
                      color='warning'
                      onChange={handleSwitch}
                    />
                    {showCaptureProgressBtn && (
                      <Button
                        style={{
                          backgroundColor: '#F2C18D',
                          fontSize: '12px',
                          color: 'black',
                          padding: '5px',
                          margin: '10px',
                        }}
                        onClick={onStartCapture}
                      >
                        CAPTURE PROGRESS!
                      </Button>
                    )}
                    {state.screenCapture && showCaptureProgressBtn && (
                      <Button
                        style={{
                          backgroundColor: '#F2C18D',
                          fontSize: '12px',
                          color: 'black',
                          padding: '5px',
                          margin: '10px',
                        }}
                        onClick={handleSave}
                      >
                        Share Your Progress!
                      </Button>
                    )}
                  </div>
                </div>
              </center>
            )}
          </ScreenCapture>
        </>
      ) : (
        <center>
          <Typography variant='h5' component='div'>
            <Alert severity='info' style={{ margin: '10px 400px 0px 400px' }}>
              No Band Events Added Yet! To add an Event, Click on 'Add Event'
            </Alert>
          </Typography>
        </center>
      )}
    </>
  )
}

export default EventDetails
