import React from 'react'
import './loader.css'

const Loader = () => {
  return (
    <div id='load'>
      <div>G</div>
      <div>N</div>
      <div>I</div>
      <div>D</div>
      <div>A</div>
      <div>O</div>
      <div>L</div>
    </div>
  )
}

export default Loader
