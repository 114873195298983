import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { addMembers, getBandUserDetails } from '../../store/actions/userActions'
import { Alert } from '@mui/material'

const AddBandMembers = ({ addMemberBtn, setAddMemberBtn }) => {
  const [name, setName] = React.useState('')
  const [role, setRole] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [email, setEmail] = React.useState('')

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleClose = () => {
    setAddMemberBtn(false)
  }

  const handleAdd = () => {
    if (name && role) {
      setAddMemberBtn(false)
      dispatch(addMembers({ _id: userInfo._id, name, role, contact, email }))
      dispatch(getBandUserDetails(userInfo._id))
    } else {
      return <Alert>Name and Role is required.</Alert>
    }
  }

  return (
    <React.Fragment>
      {addMemberBtn && (
        <Dialog open={addMemberBtn} onClose={handleClose}>
          <DialogTitle>
            <u>
              <strong>Add Band Member to</strong>{' '}
              <strong>" {userInfo.bandname.toUpperCase()} "</strong>
            </u>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'inline-flex' }}>
              <div style={{ marginRight: '10px' }}>
                <TextField
                  autoFocus
                  required
                  margin='dense'
                  id='name'
                  name='name'
                  label='Name'
                  onChange={(e) => setName(e.target.value)}
                  type='text'
                  fullWidth
                  variant='standard'
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <TextField
                  autoFocus
                  required
                  margin='dense'
                  id='role'
                  name='role'
                  label='Role in Band'
                  onChange={(e) => setRole(e.target.value)}
                  type='text'
                  fullWidth
                  variant='standard'
                />
              </div>
            </div>
            <div style={{ display: 'inline-flex' }}>
              <div style={{ marginRight: '10px' }}>
                <TextField
                  autoFocus
                  margin='dense'
                  id='contact'
                  name='contact'
                  label='Contact'
                  onChange={(e) => setContact(e.target.value)}
                  type='text'
                  fullWidth
                  variant='standard'
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <TextField
                  autoFocus
                  margin='dense'
                  id='email'
                  name='email'
                  label='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  type='text'
                  fullWidth
                  variant='standard'
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAdd} type='submit'>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default AddBandMembers
