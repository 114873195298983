import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import {
  updateEvent,
  getAllEventsByBandUserId,
} from '../../store/actions/eventActions'
import { getBandUserDetails } from '../../store/actions/userActions'
import CommonRadioGroup from '../common/CommonRadioGroup'
import SongLyrics from './SongLyrics'
import SnackBarComponent from '../common/SnackBarComponent'

const EditSong = ({ data, openEditSongModal, setOpenEditSongModal }) => {
  console.log('Aveek data', data)
  const [songname, setSongName] = useState(data[0].songname)
  const [songlink, setSongLink] = useState(data[0].songlink)
  const [scale, setScale] = useState(data[0].scale)
  const [tempo, setTempo] = useState(data[0].tempo)
  const [improvisationDone, setImporovisationDone] = useState(
    data[0].improvisationDone
  )
  const [timeTaken, setTimeTaken] = useState(data[0].timeTaken)
  const [practiseNeeded, setPractiseNeeded] = useState(data[0].practiseNeeded)
  const [performers, setPerformers] = useState(data[0].performers)
  const [lyrics, setLyrics] = useState(data[0].lyrics)
  const [snackBar, setSnackBar] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()
  const handleClose = () => {
    setOpenEditSongModal(false)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  const handlePerformers = (e) => {
    const addMember = performers + ' | ' + e.target.value
    setPerformers(addMember)
  }

  const handleAdd = async (e) => {
    // e.preventDefault()
    dispatch(
      updateEvent(params.id, data[0]._id, {
        songname,
        songlink,
        scale,
        tempo,
        improvisationDone,
        timeTaken,
        practiseNeeded,
        performers,
        lyrics,
      })
    )
    setOpenEditSongModal(false)
    await dispatch(getAllEventsByBandUserId(userInfo._id))
    setSnackBar(true)
  }

  React.useEffect(() => {
    // dispatch getBandUserDetails
    dispatch(getBandUserDetails(userInfo._id))
  }, [])

  return (
    <div style={{ margin: '400px 600px 20px 600px' }}>
      <Dialog open={openEditSongModal} onClose={handleClose}>
        <DialogTitle>
          <u>Edit Song Details</u>
        </DialogTitle>
        <DialogContentText style={{ margin: '20px 100px 20px 100px' }}>
          <center>
            You can edit{' '}
            <em>
              <u>
                name, scale, tempo, time, lyrics, improvisation and practise
              </u>
            </em>{' '}
            of the song here
          </center>
        </DialogContentText>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin='dense'
            id='songname'
            name='songname'
            label='Song Name'
            onChange={(e) => setSongName(e.target.value)}
            value={songname}
            type='text'
            fullWidth
            variant='standard'
          />
          <br />
          <TextField
            autoFocus
            required
            margin='dense'
            id='songlink'
            name='songlink'
            label='Song Link'
            onChange={(e) => setSongLink(e.target.value)}
            value={songlink}
            type='text'
            fullWidth
            variant='standard'
          />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='scale'
                name='scale'
                label='Song Scale'
                value={scale}
                onChange={(e) => setScale(e.target.value)}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='tempo'
                name='tempo'
                label='Song Tempo'
                value={tempo}
                onChange={(e) => setTempo(e.target.value)}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='time'
                name='time'
                label='Song Time'
                onChange={(e) => setTimeTaken(e.target.value)}
                value={timeTaken}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <div style={{ marginRight: '20px' }}>
              <CommonRadioGroup
                label='Improvisation Needed'
                value={improvisationDone}
                setFinalValue={setImporovisationDone}
                value1='Yes'
                value2='No'
              />
            </div>
            <div style={{ marginLeft: '20px' }}>
              <CommonRadioGroup
                label='Practise Needed'
                value={practiseNeeded}
                setFinalValue={setPractiseNeeded}
                value1='Yes'
                value2='No'
              />
            </div>
            <div>
              {user.members.length > 0 && (
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Add Performers
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={performers}
                      label='Add Performers'
                      onChange={handlePerformers}
                    >
                      {user.members.length > 0 ? (
                        user.members.map((member) => (
                          <MenuItem value={member.name}>{member.name}</MenuItem>
                        ))
                      ) : (
                        <MenuItem>NA</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </div>
          </div>

          {user.members.length > 0 && (
            <TextField
              autoFocus
              required
              margin='dense'
              id='time'
              name='time'
              label='Performers'
              onChange={(e) => setPerformers(e.target.value)}
              value={performers}
              type='text'
              fullWidth
              variant='standard'
            />
          )}
          <br />
          <br />
          <SongLyrics viewLyrics={false} data={lyrics} setLyrics={setLyrics} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd} type='submit'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {snackBar && (
        <SnackBarComponent isOpen={snackBar} msg='Song Details Updated' />
      )}
    </div>
  )
}

export default EditSong
