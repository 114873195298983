import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import Marquee from 'react-fast-marquee'
import './styles.css'

const WelcomePage = () => {
  const navigate = useNavigate()

  const login = () => {
    navigate('/login')
  }

  const openLink = () => {
    window.open('https://www.youtube.com/watch?v=HeJvlGlPVc8')
  }

  return (
    <>
      <div className='welcome'>
        <div className='welcome-buttons'>
          <div className='marquee'>
            <Marquee pauseOnClick={true} direction='right'>
              Welcome to BandApp!
            </Marquee>
          </div>
          <hr />
          <Button
            onClick={login}
            variant='contained'
            size='large'
            color='success'
          >
            Login
          </Button>{' '}
          <Button
            onClick={openLink}
            variant='contained'
            size='large'
            color='warning'
          >
            DEMO
          </Button>
          <hr />
          <div className='marquee'>
            <Marquee pauseOnClick={true}>Sync Up your Band & Events!</Marquee>
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomePage
