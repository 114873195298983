import * as React from 'react'
import dayjs from 'dayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const DateTime = ({ setDateTime, dateTimeData }) => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label='Pick Date & Time'
          defaultValue={dayjs(dateTimeData)}
          onChange={setDateTime}
        />
      </LocalizationProvider>
    </div>
  )
}

export default DateTime
