import * as React from 'react'
import { useSelector } from 'react-redux'
import { ScreenCapture } from 'react-screen-capture'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import dayjs from 'dayjs'
import ShareIcon from '@mui/icons-material/Share'

const ShareEvents = ({ data }) => {
  const [state, setState] = React.useState({ screenCapture: '' })
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleScreenCapture = (screenCapture) => {
    setState({ screenCapture })
  }

  const handleSave = () => {
    const screenCaptureSource = state.screenCapture
    const downloadLink = document.createElement('a')
    const fileName = `${data.name}.png`

    downloadLink.href = screenCaptureSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <>
      <ScreenCapture onEndCapture={handleScreenCapture}>
        {({ onStartCapture }) => (
          <div>
            <center>
              <Card sx={{ maxWidth: 400 }}>
                <CardContent>
                  <Typography variant='h5' color='text.secondary'>
                    Come & See "{userInfo.bandname}" Live!
                  </Typography>
                  <hr />
                  <Typography variant='h6' color='text.secondary'>
                    {data.name}
                  </Typography>
                  <Typography variant='h6' color='text.secondary'>
                    {dayjs(data.datetime).format('LLLL')}
                  </Typography>

                  <Typography variant='h6' color='text.secondary'>
                    Location: {data.location}
                  </Typography>
                  <hr />
                  <Typography variant='h6' color='text.secondary'>
                    See you all there!
                  </Typography>
                </CardContent>
              </Card>
              <Button
                style={{
                  backgroundColor: '#F2C18D',
                  fontSize: '12px',
                  color: 'black',
                  padding: '5px',
                  margin: '10px',
                }}
                onClick={onStartCapture}
              >
                CAPTURE!
              </Button>
            </center>
            <center>
              {state.screenCapture && (
                <Button
                  style={{
                    backgroundColor: '#F2C18D',
                    fontSize: '12px',
                    color: 'black',
                    padding: '5px',
                    margin: '10px',
                  }}
                  onClick={handleSave}
                >
                  Download Your Event Card!
                </Button>
              )}
            </center>
          </div>
        )}
      </ScreenCapture>
    </>
  )
}

export default ShareEvents
