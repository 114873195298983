import axios from 'axios'
import {
  EVENT_REGISTER_REQUEST,
  EVENT_REGISTER_SUCCESS,
  EVENT_REGISTER_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_ADD_SONG_UPDATE_REQUEST,
  EVENT_ADD_SONG_UPDATE_SUCCESS,
  EVENT_ADD_SONG_UPDATE_FAIL,
  EVENT_DELETE_SONG_REQUEST,
  EVENT_DELETE_SONG_SUCCESS,
  EVENT_DELETE_SONG_FAIL,
  EVENT_GET_SONG_SUCCESS,
  EVENT_GET_SONG_REQUEST,
  EVENT_GET_SONG_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_DELETE_FAIL,
  EVENT_UPDATE_MAIN_REQUEST,
  EVENT_UPDATE_MAIN_SUCCESS,
  EVENT_UPDATE_MAIN_FAIL,
} from '../types'

export const registerEvent = (event) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENT_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(`/api/events`, event, config)
    dispatch({ type: EVENT_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EVENT_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAllEventsByBandUserId =
  (banduserid) => async (dispatch, getState) => {
    try {
      dispatch({ type: EVENT_LIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(`/api/events/${banduserid}`, config)
      dispatch({ type: EVENT_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: EVENT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateEvent =
  (id, songid, event) => async (dispatch, getState) => {
    try {
      dispatch({ type: EVENT_UPDATE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.put(
        `/api/events/${id}/${songid}`,
        event,
        config
      )
      dispatch({ type: EVENT_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: EVENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateMainEvent =
  (id, eventData) => async (dispatch, getState) => {
    try {
      dispatch({ type: EVENT_UPDATE_MAIN_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.put(
        `/api/events/event/${id}`,
        eventData,
        config
      )
      dispatch({ type: EVENT_UPDATE_MAIN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: EVENT_UPDATE_MAIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getSongFromEvent = (id, songid) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENT_GET_SONG_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/events/${id}/${songid}`, config)
    dispatch({ type: EVENT_GET_SONG_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EVENT_GET_SONG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteSongFromEvent =
  (id, songid) => async (dispatch, getState) => {
    try {
      dispatch({ type: EVENT_DELETE_SONG_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.delete(`/api/events/${id}/${songid}`, config)
      dispatch({ type: EVENT_DELETE_SONG_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: EVENT_DELETE_SONG_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteEvent = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENT_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(`/api/events/${id}`, config)
    dispatch({ type: EVENT_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EVENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addSongToEvent = (eventid, song) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENT_ADD_SONG_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/events/${eventid}`, song, config)
    dispatch({ type: EVENT_ADD_SONG_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EVENT_ADD_SONG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
