import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import WelcomePage from './components/welcome/WelcomePage'
import Login from './components/welcome/Login'
import SignUp from './components/welcome/SignUp'
import BandPage from './components/bandusers/BandPage'
import EventDetails from './components/events/EventDetails'
import { createTheme, ThemeProvider } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: ['Kranky', 'serif'].join(','),
    fontWeight: 800,
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <header>
            <Routes>
              <Route path='/' element={<WelcomePage />} exact />
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<SignUp />} />
              <Route path='/bandpage' element={<BandPage />} />
              <Route path='/bandpage/:id' element={<EventDetails />} />
            </Routes>
          </header>
        </Router>
      </div>
      <br />
      <br />
      <div>
        <center>
          <footer>
            <p style={{ color: 'white' }}>
              Copyright 2024 | Aveek Roy |{' '}
              <a href='mailto:roy.avk@gmail.com'>Email</a>
            </p>
          </footer>
        </center>
      </div>
    </ThemeProvider>
  )
}

export default App
