import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import {
  addSongToEvent,
  getAllEventsByBandUserId,
} from '../../store/actions/eventActions'
import { getBandUserDetails } from '../../store/actions/userActions'
import SongLyrics from './SongLyrics'
import CommonRadioGroup from '../common/CommonRadioGroup'

const AddSong = ({ openAddSongModal, setOpenAddSongModal }) => {
  const [songname, setSongName] = useState('')
  const [songlink, setSongLink] = useState('')
  const [scale, setScale] = useState('')
  const [tempo, setTempo] = useState('')
  const [improvisationDone, setImporovisationDone] = useState('')
  const [timeTaken, setTimeTaken] = useState('')
  const [practiseNeeded, setPractiseNeeded] = useState('')
  const [performers, setPerformers] = useState('')
  const [lyrics, setLyrics] = useState('')
  const [viewLyrics] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenAddSongModal(false)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  const handlePerformers = (e) => {
    const addMember = performers + ' | ' + e.target.value
    setPerformers(addMember)
  }

  const handleAddSongs = () => {
    // dispatch a action to add a new song
    // e.preventDefault()
    if (songname !== '') {
      dispatch(
        addSongToEvent(params.id, {
          songname,
          songlink,
          scale,
          tempo,
          timeTaken,
          improvisationDone,
          practiseNeeded,
          performers,
          lyrics,
        })
      )
      setOpenAddSongModal(false)
      dispatch(getAllEventsByBandUserId(userInfo._id))
    } else {
      return <Alert>Song Name is required.</Alert>
    }
  }

  React.useEffect(() => {
    // dispatch getBandUserDetails
    dispatch(getBandUserDetails(userInfo._id))
  }, [])

  return (
    <div style={{ margin: '400px 600px 20px 600px' }}>
      <Dialog open={openAddSongModal} onClose={handleClose}>
        <DialogTitle>
          <u>Add Song Details</u>
        </DialogTitle>
        <DialogContentText style={{ margin: '20px 100px 20px 100px' }}>
          <center>
            You can add{' '}
            <em>
              <u>
                name, scale, tempo, time, lyrics, improvisation and practise
              </u>
            </em>{' '}
            of the song here
          </center>
        </DialogContentText>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin='dense'
            id='songname'
            name='songname'
            label='Song Name'
            onChange={(e) => setSongName(e.target.value)}
            value={songname}
            type='text'
            fullWidth
            variant='standard'
          />
          <br />
          <TextField
            autoFocus
            required
            margin='dense'
            id='songlink'
            name='songlink'
            label='Song Link'
            onChange={(e) => setSongLink(e.target.value)}
            value={songlink}
            type='text'
            fullWidth
            variant='standard'
          />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='scale'
                name='scale'
                label='Song Scale'
                value={scale}
                onChange={(e) => setScale(e.target.value)}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='tempo'
                name='tempo'
                label='Song Tempo'
                value={tempo}
                onChange={(e) => setTempo(e.target.value)}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <TextField
                autoFocus
                required
                margin='dense'
                id='time'
                name='time'
                label='Song Time'
                onChange={(e) => setTimeTaken(e.target.value)}
                value={timeTaken}
                type='text'
                fullWidth
                variant='standard'
              />
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <div style={{ marginRight: '20px' }}>
              <CommonRadioGroup
                label='Improvisation Needed'
                value={improvisationDone}
                setFinalValue={setImporovisationDone}
                value1='Yes'
                value2='No'
              />
            </div>
            <div style={{ marginLeft: '20px' }}>
              <CommonRadioGroup
                label='Practise Needed'
                value={practiseNeeded}
                setFinalValue={setPractiseNeeded}
                value1='Yes'
                value2='No'
              />
            </div>
            <div>
              {user.members.length > 0 && (
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Add Performers
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={performers}
                      label='Add Performers'
                      onChange={handlePerformers}
                    >
                      {user.members.length > 0 ? (
                        user.members.map((member) => (
                          <MenuItem value={member.name}>{member.name}</MenuItem>
                        ))
                      ) : (
                        <MenuItem>NA</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </div>
          </div>

          {user.members.length > 0 && (
            <TextField
              autoFocus
              required
              margin='dense'
              id='time'
              name='time'
              label='Performers'
              onChange={(e) => setPerformers(e.target.value)}
              value={performers}
              type='text'
              fullWidth
              variant='standard'
            />
          )}
          <br />
          <br />
          <SongLyrics
            viewLyrics={viewLyrics}
            data={lyrics}
            setLyrics={setLyrics}
          />
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddSongs} type='submit'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddSong
