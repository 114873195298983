import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import {
  getBandUserDetails,
  deleteBandMember,
} from '../../store/actions/userActions'
import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded'
import Loader from '../common/Loader'

const BandMemberList = ({ setOpen }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  console.log(user)

  const deleteMember = (memberId) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBandMember(userInfo._id, memberId))
      setOpen(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  React.useEffect(() => {
    // dispatch getBandUserDetails
    dispatch(getBandUserDetails(userInfo._id))
  }, [])

  return (
    <>
      {user.members && user.members.length > 0 ? (
        <div style={{ margin: '100px' }}>
          {loading && <Loader />}
          {loadingUserDetails && <Loader />}
          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 50 }}
              aria-label='simple table'
              style={{ overflow: 'auto' }}
              size='small'
              padding='normal'
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Band Member Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Role in Band</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Contact</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Bye Bye!</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.members &&
                  user.members
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell>{row.contact}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <center>
                          <TableCell>
                            <PersonRemoveAlt1RoundedIcon
                              onClick={() => deleteMember(row._id)}
                              fontSize='small'
                            />
                          </TableCell>
                        </center>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <center>
              <TablePagination
                component='div'
                count={user.members.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20]}
              />
            </center>
            <br />
          </TableContainer>
        </div>
      ) : (
        <center>
          <Typography variant='h5' component='div'>
            <Alert severity='info'>
              No Band Members Added Yet! To add a Band Member, Click on 'Add
              Band Members'
            </Alert>
          </Typography>
        </center>
      )}
    </>
  )
}

export default BandMemberList
