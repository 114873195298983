import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DateTime from '../common/DateTime'
import {
  registerEvent,
  getAllEventsByBandUserId,
} from '../../store/actions/eventActions'
import dayjs from 'dayjs'
import { Alert } from '@mui/material'

const AddEvent = ({ addEventBtn, setAddEventBtn }) => {
  const [name, setName] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [datetime, setDateTime] = React.useState(dayjs().format())

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleClose = () => {
    setAddEventBtn(false)
  }

  const handleAdd = async (e) => {
    setAddEventBtn(false)
    if (name && location && datetime) {
      dispatch(getAllEventsByBandUserId(userInfo._id))
      dispatch(registerEvent({ id: userInfo._id, name, location, datetime }))
    } else {
      return <Alert>Name, Location, Date & Time is required.</Alert>
    }
    dispatch(getAllEventsByBandUserId(userInfo._id))
  }

  return (
    <React.Fragment>
      {addEventBtn && (
        <Dialog
          open={addEventBtn}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault()
              const formData = new FormData(event.currentTarget)
              const formJson = Object.fromEntries(formData.entries())
              const email = formJson.email
              console.log(email)
              handleClose()
            },
          }}
        >
          <center>
            <DialogTitle>
              <u>Add Event</u>
            </DialogTitle>
          </center>
          <DialogContent>
            <DialogContentText>
              <center>
                <p>
                  Please enter{' '}
                  <em>
                    <u>Name, Location, Date & Time of the Event</u>
                  </em>
                </p>
                <p>
                  where{' '}
                  <u>
                    <strong>{userInfo.bandusername.toUpperCase()}</strong>
                  </u>{' '}
                  is going to perform
                </p>
              </center>
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin='dense'
              id='name'
              name='email'
              label='Name'
              onChange={(e) => setName(e.target.value)}
              type='text'
              fullWidth
              variant='standard'
            />
            <br />
            <TextField
              autoFocus
              required
              margin='dense'
              id='name'
              name='email'
              label='Location'
              onChange={(e) => setLocation(e.target.value)}
              type='text'
              fullWidth
              variant='standard'
            />
            <br />
            <br />
            <br />
            <DateTime setDateTime={setDateTime} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAdd} type='submit'>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default AddEvent
