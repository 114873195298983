import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DateTime from '../common/DateTime'
import {
  getAllEventsByBandUserId,
  updateMainEvent,
} from '../../store/actions/eventActions'

const EditEvent = ({ data, isEditBtnClicked, setIsEditBtnClicked }) => {
  const [name, setName] = React.useState(data[0].events.name)
  const [location, setLocation] = React.useState(data[0].events.location)
  const [datetime, setDateTime] = React.useState(data[0].events.datetime)

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleClose = async () => {
    setIsEditBtnClicked(false)
    await dispatch(getAllEventsByBandUserId(userInfo._id))
  }

  const handleUpdate = async () => {
    // window.confirm('New Feature - Yet to Come!')
    setIsEditBtnClicked(false)
    if (name && location && datetime) {
      await dispatch(updateMainEvent(data[0]._id, { name, location, datetime }))
    }
    await dispatch(getAllEventsByBandUserId(userInfo._id))
  }

  return (
    <React.Fragment>
      {isEditBtnClicked && (
        <Dialog
          open={isEditBtnClicked}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault()
              const formData = new FormData(event.currentTarget)
              const formJson = Object.fromEntries(formData.entries())
              const email = formJson.email
              console.log(email)
              handleClose()
            },
          }}
        >
          <center>
            <DialogTitle>
              <u>Edit Event</u>
            </DialogTitle>
          </center>
          <DialogContent>
            <DialogContentText>
              <center>
                <p>
                  Please edit{' '}
                  <em>
                    <u>Name, Location, Date & Time of the Event</u>
                  </em>
                </p>
                <p>
                  where{' '}
                  <u>
                    <strong>{userInfo.bandusername.toUpperCase()}</strong>
                  </u>{' '}
                  is going to perform
                </p>
              </center>
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin='dense'
              id='name'
              name='email'
              label='Name'
              onChange={(e) => setName(e.target.value)}
              type='text'
              fullWidth
              value={name}
              variant='standard'
            />
            <br />
            <TextField
              autoFocus
              required
              margin='dense'
              id='name'
              name='email'
              label='Location'
              onChange={(e) => setLocation(e.target.value)}
              type='text'
              fullWidth
              value={location}
              variant='standard'
            />
            <br />
            <br />
            <br />
            <DateTime setDateTime={setDateTime} dateTimeData={datetime} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpdate} type='submit'>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default EditEvent
