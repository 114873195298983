import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userBandMemberDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers'

import {
  eventRegisterReducer,
  eventListReducer,
  eventUpdateReducer,
  eventAddSongReducer,
  eventDeleteSongReducer,
  eventGetSongReducer,
  eventDeleteReducer,
  eventMainUpdateReducer,
} from './reducers/eventReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userBandMemberDelete: userBandMemberDeleteReducer,
  userUpdate: userUpdateReducer,
  eventRegister: eventRegisterReducer,
  eventList: eventListReducer,
  eventUpdate: eventUpdateReducer,
  eventMainUpdate: eventMainUpdateReducer,
  eventAddSong: eventAddSongReducer,
  eventDeleteSong: eventDeleteSongReducer,
  eventGetSong: eventGetSongReducer,
  eventDelete: eventDeleteReducer,
})
const middleware = [thunk]

// this block of code is responsible for bringing the items from local storage of browser - STARTS

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}
// this block of code is responsible for bringing the items from local storage of browser - ENDS

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
