import * as React from 'react'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import './lyrics.css'

const SongLyrics = ({ viewLyrics, data, setLyrics }) => {
  const handleLyrics = (e) => {
    e.preventDefault()
    setLyrics(e.target.value)
  }

  return (
    <div>
      {viewLyrics ? (
        <div class='section'>
          <p class='masked-copy texture'>
            <pre style={{ margin: '10px' }}>{data}</pre>
          </p>
        </div>
      ) : (
        <div style={{ width: '500px' }}>
          <TextareaAutosize
            aria-label='minimum height'
            minRows={10}
            placeholder='Song Lyrics....'
            value={data}
            onChange={handleLyrics}
          />
        </div>
      )}
    </div>
  )
}

export default SongLyrics
