import {
  EVENT_REGISTER_REQUEST,
  EVENT_REGISTER_SUCCESS,
  EVENT_REGISTER_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_LIST_RESET,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_ADD_SONG_UPDATE_REQUEST,
  EVENT_ADD_SONG_UPDATE_SUCCESS,
  EVENT_ADD_SONG_UPDATE_FAIL,
  EVENT_DELETE_SONG_REQUEST,
  EVENT_DELETE_SONG_SUCCESS,
  EVENT_DELETE_SONG_FAIL,
  EVENT_GET_SONG_REQUEST,
  EVENT_GET_SONG_SUCCESS,
  EVENT_GET_SONG_FAIL,
  EVENT_DELETE_FAIL,
  EVENT_DELETE_REQUEST,
  EVENT_DELETE_SUCCESS,
  EVENT_UPDATE_MAIN_REQUEST,
  EVENT_UPDATE_MAIN_SUCCESS,
  EVENT_UPDATE_MAIN_FAIL,
} from '../types'

export const eventRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_REGISTER_REQUEST:
      return { loading: true }
    case EVENT_REGISTER_SUCCESS:
      return { loading: false, eventInfo: action.payload }
    case EVENT_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventListReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { ...state, loading: true }
    case EVENT_LIST_SUCCESS:
      return { loading: false, eventListBandUser: action.payload }
    case EVENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case EVENT_LIST_RESET:
      return { eventListBandUser: {} }
    default:
      return state
  }
}

export const eventUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_UPDATE_REQUEST:
      return { ...state, loading: true }
    case EVENT_UPDATE_SUCCESS:
      return { loading: false, eventListBandUser: action.payload }
    case EVENT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventMainUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_UPDATE_MAIN_REQUEST:
      return { ...state, loading: true }
    case EVENT_UPDATE_MAIN_SUCCESS:
      return { loading: false, eventListBandUser: action.payload }
    case EVENT_UPDATE_MAIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventAddSongReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_ADD_SONG_UPDATE_REQUEST:
      return { ...state, loading: true }
    case EVENT_ADD_SONG_UPDATE_SUCCESS:
      return { loading: false, eventSongs: action.payload }
    case EVENT_ADD_SONG_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventGetSongReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_GET_SONG_REQUEST:
      return { ...state, loading: true }
    case EVENT_GET_SONG_SUCCESS:
      return { loading: false, eventSong: action.payload }
    case EVENT_GET_SONG_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventDeleteSongReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DELETE_SONG_REQUEST:
      return { loading: true }
    case EVENT_DELETE_SONG_SUCCESS:
      return { loading: false, success: true }
    case EVENT_DELETE_SONG_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const eventDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_DELETE_REQUEST:
      return { loading: true }
    case EVENT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case EVENT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
