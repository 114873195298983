import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import {
  getAllEventsByBandUserId,
  deleteEvent,
} from '../../store/actions/eventActions'
import { Card, Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import Alert from '@mui/material/Alert'
import EditEvent from './EditEvent'

const EventsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isEditBtnClicked, setIsEditBtnClicked] = useState(false)
  const [editData, setEditData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const eventList = useSelector((state) => state.eventList)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    eventListBandUser,
  } = eventList

  useEffect(() => {
    // dispatch getBandUserDetails
    const fetchBandUserDetails = async () => {
      await dispatch(getAllEventsByBandUserId(userInfo._id))
    }
    fetchBandUserDetails()
  }, [dispatch, userInfo._id, isEditBtnClicked])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleEvent = (id) => {
    navigate(`/bandpage/${id}`)
  }

  const handleEdit = async (id) => {
    await dispatch(getAllEventsByBandUserId(userInfo._id))
    const data = eventListBandUser.filter((event) => event._id === id)
    setEditData(data)
    setIsEditBtnClicked(true)
  }

  const handleDelete = async (eventid) => {
    if (
      window.confirm(
        'Are you sure?\nEverything related to this Event would be removed'
      )
    ) {
      await dispatch(deleteEvent(eventid))
      await dispatch(getAllEventsByBandUserId(userInfo._id))
    }
  }

  return (
    <>
      {eventListBandUser && eventListBandUser.length > 0 ? (
        <div style={{ margin: '20px 100px 20px 100px' }}>
          <TableContainer component={Card}>
            <Table sx={{ minWidth: 300 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Event Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Event Location</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Event Date & Time</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Event & Band Prep Details</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Edit</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Delete</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventListBandUser &&
                  eventListBandUser
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {row.events.name}
                        </TableCell>
                        <TableCell>{row.events.location}</TableCell>
                        <TableCell>
                          {dayjs(row.events.datetime).format(
                            'MM/DD/YYYY HH:mm:ss'
                          )}
                        </TableCell>
                        <TableCell>
                          <Link onClick={() => handleEvent(row._id)}>
                            Click Here!
                          </Link>
                        </TableCell>
                        <TableCell>
                          <EditIcon
                            onClick={() => handleEdit(row._id)}
                          ></EditIcon>
                          {isEditBtnClicked && (
                            <EditEvent
                              data={editData}
                              isEditBtnClicked={isEditBtnClicked}
                              setIsEditBtnClicked={setIsEditBtnClicked}
                            ></EditEvent>
                          )}
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            onClick={() => handleDelete(row._id)}
                          ></DeleteIcon>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={eventListBandUser.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15, 20]}
            />
          </TableContainer>
        </div>
      ) : (
        <center>
          <Typography variant='h5' component='div'>
            <Alert severity='info' style={{ margin: '10px 400px 0px 400px' }}>
              No Band Events Added Yet! To add an Event, Click on 'Add Event'
            </Alert>
          </Typography>
        </center>
      )}
    </>
  )
}

export default EventsList
